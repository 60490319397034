import request from '@/utils/request'


// 查询短视频社交种草分类列表
export function listRecommendCategory(query) {
  return request({
    url: '/video/recommend/category/list',
    method: 'get',
    params: query
  })
}

// 查询短视频社交种草分类分页
export function pageRecommendCategory(query) {
  return request({
    url: '/video/recommend/category/page',
    method: 'get',
    params: query
  })
}

// 查询短视频社交种草分类详细
export function getRecommendCategory(data) {
  return request({
    url: '/video/recommend/category/detail',
    method: 'get',
    params: data
  })
}

// 新增短视频社交种草分类
export function addRecommendCategory(data) {
  return request({
    url: '/video/recommend/category/add',
    method: 'post',
    data: data
  })
}

// 修改短视频社交种草分类
export function updateRecommendCategory(data) {
  return request({
    url: '/video/recommend/category/edit',
    method: 'post',
    data: data
  })
}

// 删除短视频社交种草分类
export function delRecommendCategory(data) {
  return request({
    url: '/video/recommend/category/delete',
    method: 'post',
    data: data
  })
}
